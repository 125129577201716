import { Box } from "@mui/material";
import Header from "components/Header";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import BalanceAccount from "components/BalanceAccount";

const SurplusPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      <Header
        title={t("surplus").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <BalanceAccount />
    </Box>
  );
};

export default SurplusPage;
