import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "../pages/authentication/reducer";
import snackbarReducer from "./snackbarReducer";
import loadingReducer from "./loadingReducer";
import homeReducer from "../pages/home/reducer";
export const store = configureStore({
  reducer: {
    profile: profileReducer,
    snackbar: snackbarReducer,
    loading: loadingReducer,
    home: homeReducer,
  },
});

export default store;
