import React from "react";
import { Box, Typography, Container } from "@mui/material";
import ic_card from "assets/images/ic_card.png";
import ic_card_logo from "assets/images/ic_card_logo.png";
import Text from "./Text/Typography";
import { position } from "@chakra-ui/react";
import zIndex from "@mui/material/styles/zIndex";
import { color } from "framer-motion";
const StoreCard = (props) => {
  const { name, price, orders, profit, startColor, endColor } = props;
  return (
    <Container
      maxWidth="md"
      style={{
        backgroundColor: "#000000",
        borderRadius: "15px",
        color: "#FFFFFF",
        padding: "20px",
        position: "relative",
        overflow: "hidden",
        marginBottom: "10px",
      }}
    >
      <Box
        component="img"
        src={ic_card} // Thay bằng URL của hình nền của bạn
        alt="Background"
        style={{
          width: "100%",
          height: 141,
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 1,
          zIndex: 1,
        }}
      />
      <Box
        component="img"
        src={ic_card_logo} // Thay bằng URL của logo của bạn
        alt="Logo"
        style={{
          width: 39,
          height: 39,
          marginBottom: "20px",
          zIndex: 2,
          position: "relative",
        }}
      />
      <Text
        style={{
          position: "relative",
          zIndex: 2,
          background: `linear-gradient(90deg, ${startColor},${endColor})`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: 20,
          fontWeight: "bold",
        }}
        title={name}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text
          style={{ fontSize: 10, marginRight: 10, color: "#FFFFFF" }}
          title={"Vốn đầu tư " + "$ " + price.toLocaleString('de-DE')}
        />
        <Text
          style={{ fontSize: 10, marginRight: 10 }}
          title={"Hoa Hồng " + profit + '%'}
        />
        <Text style={{ fontSize: 10 }} title={"Đơn Hàng " + orders} />
      </Box>
    </Container>
  );
};

export default StoreCard;
