import React from "react";
import { Outlet } from "react-router-dom";
import BottomNav from "../../components/BottomNav";
import { Container, Box, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import HomePage from "../../pages/home/Home";
import MatchingPage from "../../pages/matching/Matching";
import SupportPage from "../../pages/support/Support";
import HistoryPage from "../../pages/history/History";
import ProfilePage from "../../pages/profile/Profile";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const MainHomePage = () => {
  return (
    <div>
      <Outlet />
      {/* <div className="footer"> */}
      <BottomNav />
      {/* </div> */}
    </div>
  );
};

export default MainHomePage;
