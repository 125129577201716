import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
  Link,
  OutlinedInput,
  InputAdornment,
  Drawer,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import Header from "../../components/Header";
import ic_right from "../../assets/images/ic_right.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const LanguagePage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation(); // Initialize useTranslation

  const data = [
    {
      name: "English",
      id: "en",
    },
    {
      name: "Simplified Chinese",
      id: "cn",
    },
    {
      name: "Japan",
      id: "ja",
    },
    {
      name: "Portuguese",
      id: "pt",
    },
    {
      name: "Việt Nam",
      id: "vi",
    },
    {
      name: "ประเทศไทย",
      id: "th",
    },
  ];

  const handleBackClick = () => {
    navigate(-1);
  };

  const onChangeLanguage = (id) => {
    i18n.changeLanguage(id);
    localStorage.setItem("language", id);
    navigate(-1);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ px: 0 }}>
      <Header title="Language" onBackClick={handleBackClick} />
      <Box sx={{ mt: 2 }}>
        <List>
          {data.map((language) => (
            <>
              <ListItem
                key={language.id}
                button
                onClick={() => onChangeLanguage(language.id)}
              >
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: 2,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography component="p" variant="body2">
                    {language.name}
                  </Typography>
                  <img
                    src={ic_right}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </Box>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default LanguagePage;
