import React from "react";
import { Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import Text from "./Text/Typography";
import { useSelector } from "react-redux";

const BalanceAccount = () => {
  const { t } = useTranslation();
  const statistic = useSelector((state) => state.home.statistic);

  return (
    <Box
      sx={{
        borderWidth: 1,
        borderColor: "#CECECE",
        borderRadius: 2,
        mt: 2,
        mx: 2,
        bgcolor: "#FFFFFF",
        borderStyle: "solid",
      }}
    >
      <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Text
          title={t("currentBalance")}
          style={{ fontSize: 14, color: "#717171", marginTop: 16 }}
        />
        <Text
          title={"$" + statistic?.realBalance.toLocaleString()}
          style={{ fontSize: 20, color: "#0A0A0A", fontWeight: "bold" }}
        />
      </Box>
      <Divider
        style={{ borderColor: "#B88C6D", borderWidth: 2, marginTop: 14 }}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 8,
          marginBottom: 8,
        }}
      >
        <Box style={{ paddingLeft: 16, flex: 1 }}>
          <Text
            title={t("currentBalance")}
            style={{ fontSize: 12, color: "#717171" }}
          />
          <Text
            title={"$" + statistic?.realBalance.toLocaleString()}
            style={{
              fontSize: 18,
              color: "#0A0A0A",
              fontWeight: "bold",
              marginTop: 2,
            }}
          />
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <Box style={{ paddingRight: 16 }}>
          <Text
            title={t("balanceFronzen")}
            style={{ fontSize: 12, color: "#717171" }}
          />
          <Text
            title={"$" + statistic?.frozenBalance.toLocaleString()}
            style={{
              fontSize: 18,
              color: "#0A0A0A",
              fontWeight: "bold",
              marginTop: 2,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BalanceAccount;
