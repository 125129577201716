// SnackbarComponent.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { closeSnackbar } from "reducers/snackbarReducer";

const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { open, message } = useSelector((state) => state.snackbar);

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      autoHideDuration={1500}
      message={message}
      key={{ vertical: "bottom", horizontal: "center" }}
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center", // Center align the text
        minWidth: "300px",
      }}
    />
  );
};

export default SnackbarComponent;
