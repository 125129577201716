import { Box, Avatar } from "@mui/material";
import React from "react";
import moment from "moment";
import Text from "components/Text/Typography";

const MessageItem = ({
  position,
  text,
  date,
  avatar,
  setOpen,
  setImage,
  mimeType,
}) => {
  const formattedImageUrl =
    text && !/^https?:\/\//i.test(text)
      ? `https://cdn.livechat24h.com/${text}`
      : text;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: position === "right" ? "row-reverse" : "row",
        alignItems: "center",
        marginBottom: 2,
      }}
    >
      {position === "left" && (
        <Avatar
          src={avatar}
          sx={{
            marginRight: position === "right" ? 0 : 2,
            marginLeft: position === "right" ? 2 : 0,
          }}
        />
      )}
      <Box
        sx={{
          backgroundColor: position === "right" ? "#1E68D7" : "#ECEAEA",
          padding: 2,
          borderRadius: 2,
          maxWidth: "70%",
          boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
        }}
      >
        {mimeType === "image" && (
          <img
            src={formattedImageUrl}
            alt="new"
            style={{ height: 200, width: 200 }}
            onClick={() => {
              setOpen(true);
              setImage(formattedImageUrl);
            }}
          />
        )}
        {mimeType !== "image" && (
          <Text
            style={{
              color: position === "right" ? "#FFFFFF" : "#1E1E1E",
              fontSize: 14,
            }}
            title={text}
          />
        )}

        <Text
          style={{
            color: position === "right" ? "#FFFFFF" : "#1E1E1E",
            fontSize: 12,
            display: "block",
            textAlign: position === "right" ? "right" : "left",
          }}
          title={moment(date).format("HH:mm")}
        />
      </Box>
    </Box>
  );
};

export default MessageItem;
