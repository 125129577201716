import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Import cấu hình i18n
import { store } from "./reducers/store";
import { Provider } from "react-redux";
import "../src/assets/styles/styles.css";
import SnackbarComponent from "components/SnackbarComponent";
import LoadingModal from "components/LoadingModal";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <SnackbarComponent />
        <LoadingModal />
      </I18nextProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
