import React, { useEffect, useRef } from "react";
import { Box, Button, Typography, Container, Grid, Modal } from "@mui/material";
import Header from "components/Header";
import Icon from "components/Image/Icon";
import ic_search from "assets/images/ic_search.png";
import ic_camera from "assets/images/ic_camera.png";
import bg_shoes from "assets/images/bg_shoes.png";
import bg_handbag from "assets/images/bg_handbag.png";
import bg_shoulderbag from "assets/images/bg_shoulderbag.png";
import bg_sunglasses from "assets/images/bg_sunglasses.png";
import bg_newarrivals from "assets/images/bg_newarrivals.png";
import ic_right_white from "assets/images/ic_right_white.png";
import Image from "components/Image/Image";
import Text from "components/Text/Typography";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dataSlider, dataAbout, dataLevel, partners, homeSlider } from "./data";
import StoreCard from "components/StoreCard";
import apis from "services/apis";
import { useDispatch } from "react-redux";
import { setStatistic } from "./reducer";

import ic_exit from "assets/images/ic_exit2.png";
import { useTranslation } from "react-i18next";

import bg_notification from "assets/images/bg_notification.png";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let sliderRef = useRef(null);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const settingHome = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (sliderRef) {
      sliderRef.slickPlay();
    }
    getStatistic();
  }, []);

  const onNavigationItem = (item) => {
    if (item.name === "Company Qualification") {
      navigate("/main/profile/setting/company");
      return;
    }

    if (item.name === "About Us") {
      navigate("/main/profile/setting/about");
      return;
    }

    if (item.name === "Rules Description") {
      navigate("/main/profile/setting/rule");
      return;
    }

    if (item.name === "Beginner’s Guide") {
      navigate("/main/profile/setting/guide");
      return;
    }
  };

  const getStatistic = async () => {
    try {
      const response = await apis.getStatistics();
      if (response?.results) {
        dispatch(setStatistic(response?.results));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const _renderSearch = () => {
    return (
      <Box
        onClick={() => {
          window.location.href = "https://www.michaelkors.global";
        }}
        sx={{
          marginTop: "12px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#F4F4F4",
          borderRadius: "25px",
          padding: "5px 10px",
          width: "100%",
          height: 50,
          boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
        }}
      >
        <Text
          title="Search"
          style={{ fontSize: 14, color: "#717171", marginLeft: 16, flex: 1 }}
        />
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Icon src={ic_camera} width={24} height={24} />
          <Icon src={ic_search} width={24} height={24} marginLeft={8} />
        </Box>
      </Box>
    );
  };

  const _renderSlideHome = () => {
    return (
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Slider ref={(slider) => (sliderRef = slider)} {...settingHome}>
          {homeSlider.map((item) => (
            <Box
              key={item.id}
              sx={{
                padding: "0 10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={item.src}
                alt={item.alt}
                style={{
                  width: "100%",
                  height: 346,
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    );
  };

  const _renderProduction = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <img
              src={bg_shoulderbag}
              alt="Shoulder Bags"
              style={{ width: "100%", height: "auto" }}
            />
            <Typography align="center" variant="h6">
              SHOULDER BAGS
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <img
              src={bg_handbag}
              alt="Handbags"
              style={{ width: "100%", height: "auto" }}
            />
            <Typography align="center" variant="h6">
              HANDBAGS
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <img
              src={bg_shoes}
              alt="Shoes"
              style={{ width: "100%", height: "auto" }}
            />
            <Typography align="center" variant="h6">
              SHOES
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <img
              src={bg_sunglasses}
              alt="Sunglasses"
              style={{ width: "100%", height: "auto" }}
            />
            <Typography align="center" variant="h6">
              SUNGLASSES
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const _renderSlide = () => {
    return (
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: 2,
          marginBottom: 3,
        }}
      >
        <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
          {dataSlider.map((item) => (
            <Box key={item.id} sx={{ padding: "0 5px" }}>
              <img
                src={item.src}
                alt={item.alt}
                style={{ width: 76, height: 76 }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    );
  };

  const _renderAbout = () => {
    return (
      <Grid container spacing={8} style={{ justifyContent: "center" }}>
        {dataAbout.map((item) => {
          return (
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                onClick={() => onNavigationItem(item)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={item.src} style={{ width: 24, height: 24 }} />
                <Text
                  style={{
                    marginTop: 8,
                    color: "#717171",
                    fontSize: 10,
                    textAlign: "center",
                  }}
                  title={item.name}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const _renderLevel = () => {
    return (
      <>
        {dataLevel.map((item) => {
          return (
            <StoreCard
              name={item.name}
              price={item.price}
              orders={item.orders}
              profit={item.profit}
              startColor={item.startColor}
              endColor={item.endColor}
            />
          );
        })}
      </>
    );
  };

  const _renderLogo = () => {
    return (
      <Grid container spacing={1} sx={{ paddingBottom: 8 }}>
        {partners.map((partner, index) => (
          <Grid item xs={4} key={index}>
            <Box
              sx={{
                background: "#FFFFFF",
                height: "55px",
              }}
            >
              <img
                src={partner.logo}
                alt={partner.name}
                style={{ height: 65, width: 110 }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderNoted = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: 2,
        }}
      >
        <Button
          onClick={() => setOpen(false)}
          style={{
            position: "absolute",
            top: -20,
            right: -20,
            height: 45,
            width: 45,
          }}
        >
          <Icon src={ic_exit} width={45} height={45} />
        </Button>
        <Box
          sx={{
            maxHeight: "400px", // Set a maximum height
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <Text
            title={t("notification01")}
            style={{ fontSize: 14, color: "#0A0A0A" }}
          />
          <Text
            title={t("notification02")}
            style={{ fontSize: 14, color: "#0A0A0A" }}
          />
          <Text
            title={t("notification03")}
            style={{ fontSize: 14, color: "#0A0A0A" }}
          />
          <Text
            title={t("notification04")}
            style={{ fontSize: 14, color: "#0A0A0A" }}
          />
          <Image
            src={bg_notification}
            style={{ height: 320, width: "100%", marginTop: 12 }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Box sx={{ marginTop: 2 }}>
        <Header type={"home"} title={"MICHAEL KORS"} />
      </Box>
      {_renderSearch()}
      <Typography
        style={{
          marginTop: 24,
          color: "#717171",
          fontFamily: "Inter",
          fontSize: "12",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginBottom: 12,
        }}
      >
        PRICES AS MARKED | *TERMS
      </Typography>
      {_renderSlideHome()}
      <Typography
        style={{
          marginTop: 24,
          color: "#0A0A0A",
          fontFamily: "Inter",
          fontSize: "20",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        NEW UTILITY
      </Typography>
      <Text
        style={{
          marginTop: 8,
          color: "#717171",
          fontSize: 12,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          textAlign: "center",
          marginBottom: 12,
        }}
        title="NEW UTILITY EARTH TONES, DENIM AND UTILITARIAN DETAILS -SO THINGS JUST WORK."
      />
      {_renderProduction()}
      <Image
        src={bg_newarrivals}
        style={{ height: 378, width: "100%", marginTop: 24 }}
      />
      <Text
        style={{
          marginTop: 8,
          color: "#0A0A0A",
          fontSize: "14px",
          marginLeft: 70,
        }}
        title="All the latest for the new season and beyond."
      />
      <Button
        onClick={() => {
          window.location.href = "https://www.michaelkors.global";
        }}
        sx={{
          padding: "10px 20px",
          backgroundColor: "#333333",
          borderRadius: 3,
          alignItems: "center",
          textTransform: "none",
          color: "#FFFFFF",
          marginLeft: "70px",
          marginTop: "8px",
        }}
        endIcon={<Icon src={ic_right_white} width={24} height={24} />}
      >
        <Text title={"Shop now".toUpperCase()} />
      </Button>
      {_renderSlide()}
      {_renderAbout()}
      <Text
        style={{
          marginTop: 10,
          color: "#0A0A0A",
          fontSize: "20px",
          fontWeight: "600",
          marginBottom: 10,
        }}
        title={"CẤP BẬC GIAN HÀNG".toUpperCase()}
      />
      {_renderLevel()}
      <Text
        style={{
          marginTop: 10,
          color: "#0A0A0A",
          fontSize: "20px",
          fontWeight: "600",
          marginBottom: 8,
        }}
        title={"PARTNER".toUpperCase()}
      />
      {_renderLogo()}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {renderNoted()}
      </Modal>
    </Box>
  );
};

export default HomePage;
