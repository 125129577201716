import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "components/Header";
import Text from "components/Text/Typography";
import bg_support from "assets/images/bg_support.png";
import Image from "components/Image/Image";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import apis from "services/apis";
const SupportPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const message = "The customer is about to access the support page.";
    await apis.sendMessage({
      message,
    });
    navigate("/live-chat");
    // window.location.href = "https://direct.lc.chat/17412480/";
  };
  return (
    <Box>
      <Header title={t("supportUs").toUpperCase()} hideBack />
      <Box
        style={{
          marginTop: 20,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 65,
        }}
      >
        <Text
          title={t("support1")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("support2")}
          style={{
            fontSize: 14,
            color: "#333333",
            fontWeight: "bold",
            marginTop: 10,
          }}
        />
        <Image
          src={bg_support}
          style={{ marginTop: 10, height: 191, width: "100%" }}
        />
        <Text
          title={t("faq").toUpperCase()}
          style={{
            fontSize: 14,
            color: "#717171",
            fontWeight: "bold",
            marginTop: 10,
          }}
        />
        <Box style={{ marginTop: 10 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Text
                title={t("ask1")}
                style={{
                  fontSize: 14,
                  color: "#333333",
                }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Text
                title={t("ans1")}
                style={{
                  fontSize: 14,
                  color: "#333333",
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Text
                title={t("ask2")}
                style={{
                  fontSize: 14,
                  color: "#333333",
                }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Text
                title={t("ans2")}
                style={{
                  fontSize: 14,
                  color: "#333333",
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Text
                title={t("ask3")}
                style={{
                  fontSize: 14,
                  color: "#333333",
                }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Text
                title={t("ans3")}
                style={{
                  fontSize: 14,
                  color: "#333333",
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Text
                title={t("ask4")}
                style={{
                  fontSize: 14,
                  color: "#333333",
                }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Text
                title={t("ans4")}
                style={{
                  fontSize: 14,
                  color: "#333333",
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Button
          onClick={onSubmit}
          fullWidth
          variant="contained"
          style={{
            height: 45,
            borderRadius: 8,
            marginTop: 50,
            backgroundColor: "#333333",
            color: "#FFFFFF",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {t("connectSupport").toUpperCase()}
        </Button>
      </Box>
    </Box>
  );
};

export default SupportPage;
