import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const LoadingModal = () => {
  const isLoading = useSelector((state) => state.loading);

  return (
    <Modal
      open={isLoading}
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 200,
          height: 200,
          borderRadius: 1,
        }}
      >
        {/* <CircularProgress /> */}
        <PulseLoader
          color={"#FFFFFF"}
          loading={isLoading}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </Box>
    </Modal>
  );
};

export default LoadingModal;
