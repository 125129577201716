import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import Header from "components/Header";
import Text from "components/Text/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { startLoading, stopLoading } from "reducers/loadingReducer";
import { showSnackbar } from "reducers/snackbarReducer";
import apis from "services/apis";
const InfoDeliveryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fullName, setFullName] = useState("");
  const [numberPhone, setNumberPhone] = useState("");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getAddress();
  }, []);

  const formatString = (input) => {
    if (input.length <= 4) return input;
    const start = input.slice(0, 3);
    const end = input.slice(-3);
    const middle = "*".repeat(input.length - 6);
    return start + middle + end;
  };

  const getAddress = async () => {
    try {
      const response = await apis.getAddress();
      if (response?.results) {
        setIsUpdate(true);
        setFullName(response?.results?.fullName);
        setNumberPhone(formatString(response?.results?.phone));
        setRegion(response?.results?.area);
        setAddress(response?.results?.location);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = () => {
    if (isUpdate) {
      dispatch(showSnackbar(t("updatedDelivery")));
      return;
    }

    if (!fullName) {
      dispatch(showSnackbar(t("enterFullName")));
      return;
    }

    if (!numberPhone) {
      dispatch(showSnackbar(t("enterPhone")));
      return;
    }

    if (!region) {
      dispatch(showSnackbar(t("enterRegion")));
      return;
    }
    if (!address) {
      dispatch(showSnackbar(t("enterAddress")));
      return;
    }

    updateAddress();
  };

  const updateAddress = async () => {
    try {
      dispatch(startLoading());
      const params = {
        fullName: fullName,
        phone: numberPhone,
        area: region,
        location: address,
      };
      await apis.updateAddress(params);

      dispatch(stopLoading());
      dispatch(showSnackbar(t("updateAddressSuccess")));
      setIsUpdate(true);
    } catch (error) {
      console.log("error", error);
      dispatch(stopLoading());
      dispatch(showSnackbar(t("updateAddressFail")));
    }
  };
  return (
    <Box>
      <Header
        title={t("infoDelivery").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Text
          title={t("fullName")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          fullWidth
          placeholder={t("fullName")}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
        />
        <Text
          title={t("numberPhone")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          value={numberPhone}
          onChange={(e) => setNumberPhone(e.target.value)}
          fullWidth
          placeholder={t("numberPhone")}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
        />
        <Text
          title={t("region")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          fullWidth
          placeholder={t("region")}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
        />
        <Text
          title={t("address")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
          placeholder={t("address")}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
        />
        <Button
          onClick={onSubmit}
          fullWidth
          variant="contained"
          style={{
            height: 45,
            borderRadius: 8,
            marginTop: 50,
            backgroundColor: "#333333",
            color: "#FFFFFF",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {t("identify").toUpperCase()}
        </Button>
      </Box>
    </Box>
  );
};
export default InfoDeliveryPage;
