import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const Text = ({ title, style }) => {
  return (
    <Typography
      style={{
        fontFamily: "Inter",
        fontSize: "18px",
        ...style,
      }}
    >
      {title}
    </Typography>
  );
};

Text.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
};

export default Text;
