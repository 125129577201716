import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ic_home from "../assets/images/ic_home.png";
import ic_history from "../assets/images/ic_history.png";
import ic_matching from "../assets/images/ic_matching.png";
import ic_support from "../assets/images/ic_support.png";
import ic_profile from "../assets/images/ic_profile.png";

import Icon from "../components/Image/Icon";
import { useTranslation } from "react-i18next";

const BottomNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) navigate("home");
    if (newValue === 1) navigate("history");
    if (newValue === 2) navigate("matching");
    if (newValue === 3) navigate("support");
    if (newValue === 4) navigate("profile");
  };

  const commonStyles = {
    "& .MuiBottomNavigationAction-label": {
      fontSize: 12,
      whiteSpace: "normal",
      lineHeight: 1.2,
      height: "2.4em", // Adjust the height as needed
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#CECECE",
    },
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        zIndex: 1000,
      }}
    >
      <BottomNavigationAction
        showLabel
        label={t("home")}
        icon={<Icon src={ic_home} width={24} height={24} />}
        sx={{
          ...commonStyles,
          "& .MuiBottomNavigationAction-label": {
            ...commonStyles["& .MuiBottomNavigationAction-label"],
            color: value === 0 ? "#333333" : "#CECECE",
          },
        }}
      />
      <BottomNavigationAction
        showLabel
        label={t("records")}
        icon={<Icon src={ic_history} width={24} height={24} />}
        sx={{
          ...commonStyles,
          "& .MuiBottomNavigationAction-label": {
            ...commonStyles["& .MuiBottomNavigationAction-label"],
            color: value === 1 ? "#333333" : "#CECECE",
          },
        }}
      />
      <BottomNavigationAction
        showLabel
        label={t("start").toUpperCase()}
        icon={<Icon src={ic_matching} width={24} height={24} />}
        sx={{
          ...commonStyles,
          "& .MuiBottomNavigationAction-label": {
            ...commonStyles["& .MuiBottomNavigationAction-label"],
            color: value === 2 ? "#333333" : "#CECECE",
          },
        }}
      />
      <BottomNavigationAction
        showLabel
        label={t("support")}
        icon={<Icon src={ic_support} width={24} height={24} />}
        sx={{
          ...commonStyles,
          "& .MuiBottomNavigationAction-label": {
            ...commonStyles["& .MuiBottomNavigationAction-label"],
            color: value === 3 ? "#333333" : "#CECECE",
          },
        }}
      />
      <BottomNavigationAction
        showLabel
        label={t("me")}
        icon={<Icon src={ic_profile} width={24} height={24} />}
        sx={{
          ...commonStyles,
          "& .MuiBottomNavigationAction-label": {
            ...commonStyles["& .MuiBottomNavigationAction-label"],
            color: value === 4 ? "#333333" : "#CECECE",
          },
        }}
      />
    </BottomNavigation>
  );
};

export default BottomNav;
