import {
  Box,
  OutlinedInput,
  InputAdornment,
  Button,
  Divider,
  Checkbox,
} from "@mui/material";
import Header from "components/Header";
import Text from "components/Text/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ic_deposit_input from "assets/images/ic_deposit_input.png";
import { useNavigate } from "react-router-dom";
import ic_password from "assets/images/ic_password.png";
import BalanceAccount from "components/BalanceAccount";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "reducers/snackbarReducer";
import { startLoading, stopLoading } from "reducers/loadingReducer";
import apis from "services/apis";
import ic_showpassword from "../../assets/images/ic_showpassword.png";
import ic_hidepassword from "../../assets/images/ic_hidepassword.png";
import IconButton from "@mui/material/IconButton";
import { setStatistic } from "pages/home/reducer";
const WithdrawPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statistic = useSelector((state) => state.home.statistic);
  const profile = useSelector((state) => state.profile?.profile);

  const [checked, setChecked] = useState(false);
  const [amount, setAmount] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (checked) {
      setAmount(parseFloat(statistic?.realBalance));
    }
  }, [checked]);

  const onSubmit = () => {
    if (!amount) {
      dispatch(showSnackbar(t("enterAmount")));
      return;
    }

    if (amount > statistic?.realBalance) {
      dispatch(showSnackbar(t("balanceNotEnough")));
      return;
    }

    if (!password) {
      dispatch(showSnackbar(t("enterPassword")));
      return;
    }

    onCallWithdraw();
  };

  const getStatistic = async () => {
    try {
      const response = await apis.getStatistics();
      if (response?.results) {
        dispatch(setStatistic(response?.results));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const onCallWithdraw = async () => {
    try {
      dispatch(startLoading());
      const params = {
        amount: parseFloat(amount),
        password: password,
      };
      await apis.requestWithdraw(params);
      dispatch(stopLoading());
      dispatch(showSnackbar(t("withdrawSuccess")));
      getStatistic();
    } catch (error) {
      console.log("error", error);
      dispatch(stopLoading());
      if (error?.error === "error.ERROR_PASSWORD_LEVEL2") {
        dispatch(showSnackbar(t("errorPasswordOrder")));
        return;
      }

      if (error?.error === "error.ERROR_EMPTY_PAYMENT") {
        dispatch(showSnackbar(t("updateInfomationBank")));
        return;
      }

      if (error?.error === "error.ERROR_MIN_WITHDRAW_MISSION") {
        dispatch(
          showSnackbar(
            t("minOrder01") +
              " " +
              profile?.package?.totalOrder +
              " " +
              t("minOrder02")
          )
        );
        return;
      }

      if (error?.error === "error.ERROR_TIME_WITHDRAW") {
        dispatch(showSnackbar(t("maxWithdraw")));
        return;
      }

      dispatch(showSnackbar(t("withdrawFail")));
    }
  };

  return (
    <Box>
      <Header
        title={t("withdraw").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <BalanceAccount />
      <Box style={{ paddingLeft: 24, paddingRight: 24 }}>
        <Text
          title={t("withdrawAmount") + " ($)"}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
          placeholder={"0"}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
          startAdornment={
            <InputAdornment position="start">
              <img
                src={ic_deposit_input}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </InputAdornment>
          }
        />
        <Box style={{ display: "flex", alignItems: "center", marginTop: 4 }}>
          <Checkbox
            style={{ marginLeft: -10 }}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <Text
            title={t("withdrawAll")}
            style={{ fontSize: 14, color: "#333333" }}
          />
        </Box>
      </Box>
      <Divider style={{ marginTop: 8 }} />
      <Box style={{ paddingLeft: 24, paddingRight: 24 }}>
        <Text
          title={t("enterPasswordBalance")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />

        <OutlinedInput
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
          placeholder={t("enterPassword")}
          type={showPassword ? "text" : "password"}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
          startAdornment={
            <InputAdornment position="start">
              <img
                src={ic_password}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </InputAdornment>
          }
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? (
                <img
                  src={ic_hidepassword}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              ) : (
                <img
                  src={ic_showpassword}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              )}
            </IconButton>
          }
        />
        <Button
          onClick={onSubmit}
          fullWidth
          variant="contained"
          style={{
            height: 45,
            borderRadius: 8,
            marginTop: 50,
            backgroundColor: "#333333",
            color: "#FFFFFF",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {t("withdrawNow").toUpperCase()}
        </Button>
      </Box>
    </Box>
  );
};

export default WithdrawPage;
