import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import Header from "components/Header";
import Text from "components/Text/Typography";
import OrderCard from "components/OrderCard";
import apis from "services/apis";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { showSnackbar } from "reducers/snackbarReducer";
import { setStatistic } from "pages/home/reducer";
const HistoryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);

  const [listOrder, setListOrder] = useState([]);
  const statistic = useSelector((state) => state.home.statistic);
  const [messageMatching, setMessageMatching] = useState("");

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  const tabs = [
    { label: t("allOrders") },
    { label: t("pendingOrders") },
    { label: t("done") },
    { label: t("frezonOrders") },
  ];

  useEffect(() => {
    getListOrder();
    getStatistic();
  }, []);

  const getStatistic = async () => {
    try {
      const response = await apis.getStatistics();
      if (response?.results) {
        dispatch(setStatistic(response?.results));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getListOrder = async () => {
    try {
      const response = await apis.getListOrder();
      if (response?.results?.orders) {
        setListOrder(response?.results?.orders.reverse());
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const filterOrders = (orders, index) => {
    switch (index) {
      case 1:
        return orders.filter((order) => order.status === "pending");
      case 2:
        return orders.filter((order) => order.status === "success");
      case 3:
        return orders.filter((order) => order.status === "frozen");
      default:
        return orders;
    }
  };

  const _renderTabs = () => {
    return (
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          borderColor: "divider",
          marginTop: 2,
        }}
      >
        {tabs.map((tab, index) => (
          <Box
            key={index}
            onClick={() => handleTabClick(index)}
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
              marginRight: 4,
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              color: selectedTab === index ? "black" : "gray",
              position: "relative",
              "&:hover": { color: "black" },
              ...(selectedTab === index && {
                "&::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: "4px",
                  backgroundColor: "#D2A679",
                },
              }),
            }}
          >
            <Text style={{ fontSize: 15 }} title={tab.label} />
          </Box>
        ))}
      </Box>
    );
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = async (order) => {
    try {
      const response = await apis.updateOrder(order?._id);
      if (response?.results) {
        setOpen(true);
        setMessageMatching(t("messMatching1"));
        await delay(1500);
        setMessageMatching(t("messMatching2"));
        await delay(1500);
        setMessageMatching(t("messMatching3"));
        await delay(1500);
        setMessageMatching(t("messMatching4"));
        await delay(1500);
        setMessageMatching(t("messMatching5"));
        await delay(1500);
        setMessageMatching(t("messMatching6"));
        await delay(1500);
        setOpen(false);
        dispatch(showSnackbar(t("orderDone")));
        getListOrder();
        getStatistic();
      }
    } catch (error) {
      console.log("error", error);
      if (error?.error === "error.ERROR_TOPUP_REQUIRED") {
        dispatch(showSnackbar(t("lackOfMoney")));
        navigate("/main/profile/deposit");
      }
    }
  };

  const _renderListOrder = () => {
    const filteredOrders = filterOrders(listOrder, selectedTab);
    return (
      <Box style={{ marginTop: 14, paddingBottom: 50 }}>
        {filteredOrders.map((order, index) => (
          <OrderCard
            key={index}
            order={order}
            onSubmit={() => onSubmit(order)}
          />
        ))}
      </Box>
    );
  };

  const _renderLoading = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          p: 4,
          borderRadius: 1,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PulseLoader
          color={"#FFFFFF"}
          loading={true}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <Box>
          <Text
            title={messageMatching}
            style={{
              fontSize: 18,
              color: "#FFFFFF",
              textAlign: "left",
              marginTop: 10,
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Header title={t("records").toUpperCase()} hideBack />
      <Box style={{ paddingLeft: 16, paddingRight: 16, marginTop: 20 }}>
        <Box
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text title={t("balanceAccount")} style={{ flex: 1 }} />
          <Box
            style={{
              backgroundColor: "#F4F4F4",
              height: 40,
              width: 134,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              borderRadius: 5,
            }}
          >
            <Text
              title={"$" + statistic?.realBalance.toLocaleString() + " USD"}
            />
          </Box>
        </Box>
        {_renderTabs()}
        {_renderListOrder()}
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {_renderLoading()}
      </Modal>
    </Box>
  );
};

export default HistoryPage;
