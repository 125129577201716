const { default: network } = require("./network");

const register = (data) => {
  return new Promise((resolve, reject) => {
    network
      .unAuthorizedRequest("authentication/register", "POST", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const login = (data) => {
  return new Promise((resolve, reject) => {
    network
      .unAuthorizedRequest("authentication/login", "POST", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProfile = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/profile", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getStatistics = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/statistic", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getListOrder = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("order/my?limit=9999", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const generateOrder = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("order/generate", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateOrder = (id) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest(`order/place/${id}`, "PUT")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateBank = (data) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/bank", "POST", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getBank = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/bank", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const requestWithdraw = (data) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("withdraw", "POST", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMyPackage = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("package/my?status=true", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const historyDeposit = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("transaction/user?type=deposite&limit=9999", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const historyWithdraw = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("withdraw?limit=9999", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const changePassword = (data) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/change-password", "PUT", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const changePasswordOrder = (data) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/password-level2", "PUT", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAddress = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/address", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateAddress = (data) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/address", "PUT", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getListProduct = () => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("public/product-random?limit=6", "GET")
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendMessage = (data) => {
  return new Promise((resolve, reject) => {
    network
      .authorizedRequest("user/send-message-bot", "POST", data)
      .then((response) => {
        if (response?.statusCode >= 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  register,
  login,
  getProfile,
  getStatistics,
  getListOrder,
  generateOrder,
  updateOrder,
  updateBank,
  getBank,
  requestWithdraw,
  getMyPackage,
  historyDeposit,
  changePassword,
  changePasswordOrder,
  getAddress,
  updateAddress,
  historyWithdraw,
  getListProduct,
  sendMessage
};
