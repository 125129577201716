import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import Header from "components/Header";
import Text from "components/Text/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ic_deposit_input from "assets/images/ic_deposit_input.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const DepositPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const amounts = [200, 500, 1000, 3000, 5000, 10000, 20000, 30000];
  const [amount, setAmount] = useState(0);
  const statistic = useSelector((state) => state.home.statistic);

  return (
    <Box>
      <Header
        title={t("numberDeposit").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Text
          title={t("enterAmount") + "($)"}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          value={amount}
          type="number"
          fullWidth
          placeholder={"0"}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
          startAdornment={
            <InputAdornment position="start">
              <img
                src={ic_deposit_input}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </InputAdornment>
          }
        />
        <Box style={{ display: "flex" }}>
          <Text
            title={t("walletbalance")}
            style={{ marginTop: 8, fontSize: 12, color: "#717171" }}
          />
          <Text
            title={"$" + statistic?.realBalance.toLocaleString()}
            style={{
              marginTop: 8,
              fontSize: 12,
              color: "#717171",
              fontWeight: "bold",
              marginLeft: 2,
            }}
          />
        </Box>
        <Divider style={{ marginTop: 16 }} />
        <Box style={{ marginTop: 24 }}>
          <Grid container spacing={2}>
            {amounts.map((amount, index) => (
              <Grid item xs={4} key={index}>
                <Button
                  onClick={() => {
                    setAmount(amount);
                  }}
                  variant="outlined"
                  fullWidth
                  style={{
                    borderColor: "#CECECE",
                    borderWidth: 1.5,
                    borderRadius: 10,
                    color: "#0A0A0A",
                    fontWeight: "bold",
                  }}
                >
                  {amount.toLocaleString()}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Button
            onClick={() => {
              window.location.href = "https://direct.lc.chat/17412480/";
            }}
            fullWidth
            variant="contained"
            style={{
              height: 45,
              borderRadius: 8,
              marginTop: 50,
              backgroundColor: "#333333",
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {t("depositNow").toUpperCase()}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DepositPage;
