import React, { useState } from "react";
import { Box, Button, List, ListItem } from "@mui/material";
import Text from "components/Text/Typography";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Image from "components/Image/Image";
import ic_right from "assets/images/ic_right.png";
import ic_language from "assets/images/ic_language.png";
import ic_agree from "assets/images/ic_agree.png";
import ic_rule from "assets/images/ic_rule.png";
import ic_about_us from "assets/images/ic_about_us.png";
const SettingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onNavigate = (link) => {
    navigate(link);
  };
  const catalog = [
    { icon: ic_language, name: t("language"), link: "/language" },
    {
      icon: ic_agree,
      name: t("agreeUser"),
      link: "agree",
    },
    {
      icon: ic_rule,
      name: t("ruleDescription"),
      link: "rule",
    },
    {
      icon: ic_about_us,
      name: t("about"),
      link: "about",
    },
  ];

  const onLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <Box>
      <Header
        title={t("security").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box>
        <List>
          {catalog.map((item, index) => (
            <ListItem
              onClick={() => onNavigate(item.link)}
              key={index}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                alignItems: "center",
              }}
            >
              <Image src={item.icon} style={{ height: 20, width: 20 }} />
              <Text
                title={item.name}
                style={{
                  marginLeft: 10,
                  fontSize: 18,
                  color: "#333333",
                  flex: 1,
                }}
              />
              <Image src={ic_right} style={{ height: 20, width: 20 }} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Button
          onClick={onLogout}
          fullWidth
          variant="contained"
          style={{
            height: 45,
            borderRadius: 8,
            marginTop: 50,
            backgroundColor: "#FFFFFF",
            color: "#0A0A0A",
            borderWidth: 2,
            borderColor: "#CECECE",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {t("logout").toUpperCase()}
        </Button>
      </Box>
    </Box>
  );
};
export default SettingPage;
