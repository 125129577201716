import React, { useState } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Text from "components/Text/Typography";

const AgreePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <Header
        title={t("agreeUser").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box
        style={{
          marginTop: 16,
          paddingBottom: 60,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Text
          title={t("agreeAbout")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("accountRegistration")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("accountRegistration1")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("accountRegistration2")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("accountRegistration3")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("accountRegistration4")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("privacyProtection")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("privacyProtection1")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("privacyProtection2")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("privacyProtection3")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("privacyProtection4")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("contentRules")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("contentRules1")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("contentRules2")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("contentRules3")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("usageRules")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("usageRules1")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("usageRules2")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("usageRules3")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("usageRules4")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text title={t("others")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("others1")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("others2")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("others3")} style={{ fontSize: 14, color: "#333333" }} />
        <Text
          title={t("copyright")}
          style={{ fontSize: 14, color: "#333333", marginTop: 15 }}
        />
      </Box>
    </Box>
  );
};
export default AgreePage;
