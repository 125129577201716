import React from "react";
import PropTypes from "prop-types";

const Icon = ({ src, width = 24, height = 24, marginLeft = 0 }) => {
  return <img src={src} alt="icon" style={{ width, height, marginLeft }} />;
};

Icon.propTypes = {
  src: PropTypes.string.isRequired,

  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Icon;
