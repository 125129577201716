import React, { useState } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Text from "components/Text/Typography";

const AboutPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <Header
        title={t("about").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box
        style={{
          marginTop: 16,
          paddingBottom: 60,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Text
          title={t("companyIntro")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("brandDetails")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("brandPhilosophy")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("storeLocations")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("companyCulture")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("designerProfile")}
          style={{ fontSize: 14, color: "#333333" }}
        />
      </Box>
    </Box>
  );
};
export default AboutPage;
