import ic_company from "assets/images/ic_company.png";
import ic_guide from "assets/images/ic_guide.png";
import ic_rules from "assets/images/ic_rules.png";
import ic_about from "assets/images/ic_about.png";
import ic_macys from "assets/images/ic_macys.png";
import ic_neiman from "assets/images/ic_neiman.png";
import ic_amazon from "assets/images/ic_amazon.png";
import ic_shopbop from "assets/images/ic_shopbop.png";
import ic_bergdorf from "assets/images/ic_bergdorf.png";
import ic_laks from "assets/images/ic_laks.png";
import bg_home from "assets/images/bg_home.png";
import bg_home2 from "assets/images/bg_home2.png";
import bg_home3 from "assets/images/bg_home3.png";
import bg_home4 from "assets/images/bg_home4.png";
const homeSlider = [
  {
    id: 1,
    src: bg_home,
    alt: "Image 1",
  },
  {
    id: 2,
    src: bg_home2,
    alt: "Image 2",
  },
  {
    id: 3,
    src: bg_home3,
    alt: "Image 3",
  },
  {
    id: 4,
    src: bg_home4,
    alt: "Image 4",
  },
];
const dataSlider = [
  {
    id: 1,
    src: "https://michaelkors.scene7.com/is/image/MichaelKors/30S4G9RS3T-0001_1?$large$",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://michaelkors.scene7.com/is/image/MichaelKors/40T4AOMS3L-1999_1?$medium$",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://michaelkors.scene7.com/is/image/MichaelKors/30S4SBAL2L-0230_1?$large$",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://michaelkors.scene7.com/is/image/MichaelKors/30T4GZAT4L-0001_1?$medium$",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://michaelkors.scene7.com/is/image/MichaelKors/35F3GTVT8B-0230_1?$medium$",
    alt: "Image 5",
  },
];

const dataAbout = [
  {
    name: "Company Qualification",
    src: ic_company,
  },
  {
    name: "Beginner’s Guide",
    src: ic_guide,
  },
  { name: "Rules Description", src: ic_rules },
  {
    name: "About Us",
    src: ic_about,
  },
];

const dataLevel = [
  {
    name: "Gian hàng số 1",
    price: 200,
    orders: 60,
    profit: 0.3,
    startColor: "#FC9258",
    endColor: "#FFEA91",
  },
  {
    name: "Gian hàng số 2",
    price: 1000,
    orders: 80,
    profit: 0.4,
    startColor: "#DF913E",
    endColor: "#CB512C",
  },
  {
    name: "Gian hàng số 3",
    price: 3000,
    orders: 100,
    profit: 0.5,
    startColor: "#FDFBFF",
    endColor: "#DB1BD0",
  },
  {
    name: "Gian hàng số 4",
    price: 5000,
    orders: 120,
    profit: 0.6,
    startColor: "#B6B6B6",
    endColor: "#E4E4E4",
  },
  {
    name: "Gian hàng số 5",
    price: 10000,
    orders: 140,
    profit: 0.7,
    startColor: "#FDDA75",
    endColor: "#D3930C",
  },
  {
    name: "Gian hàng số 6",
    price: 30000,
    orders: 160,
    profit: 0.8,
    startColor: "#20469F",
    endColor: "#CDE8F9",
  },
  {
    name: "Gian hàng số 7",
    price: 50000,
    orders: 180,
    profit: 1,
    startColor: "#C8F337",
    endColor: "#60C423",
  },
  {
    name: "Gian hàng số 8",
    price: 80000,
    orders: 200,
    profit: 1.5,
    startColor: "#DE192A",
    endColor: "#FD4857",
  },
  {
    name: "Gian hàng số 9",
    price: 150000,
    orders: 240,
    profit: 2,
    startColor: "#FEB801",
    endColor: "#FFFE03",
  },
];

const partners = [
  { name: "Macy's", logo: ic_macys },
  { name: "Neiman Marcus", logo: ic_neiman },
  { name: "Amazon", logo: ic_amazon },
  { name: "Shopbop", logo: ic_shopbop },
  { name: "Bergdorf Goodman", logo: ic_bergdorf },
  { name: "Saks Fifth Avenue", logo: ic_laks },
];

export { dataSlider, dataAbout, dataLevel, partners, homeSlider };
