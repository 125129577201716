import { Box, Typography, Divider, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Text from "components/Text/Typography";

import bg_lv1 from "assets/images/bg_lv1.png";
import bg_lv2 from "assets/images/bg_lv2.png";
import bg_lv3 from "assets/images/bg_lv3.png";
import bg_lv4 from "assets/images/bg_lv4.png";
import bg_lv5 from "assets/images/bg_lv5.png";
import bg_lv6 from "assets/images/bg_lv6.png";
import bg_lv7 from "assets/images/bg_lv7.png";
import bg_lv8 from "assets/images/bg_lv8.png";
import bg_lv9 from "assets/images/bg_lv9.png";
import bg_lv0 from "assets/images/bg_lv0.png";

import ic_deposit from "assets/images/ic_deposit.png";
import ic_withdraw from "assets/images/ic_withdraw.png";
import ic_invoice from "assets/images/ic_invoice.png";
import ic_surplus from "assets/images/ic_surplus.png";
import ic_share from "assets/images/ic_share.png";
import ic_bank from "assets/images/ic_bank.png";
import ic_security from "assets/images/ic_security.png";
import ic_setting from "assets/images/ic_setting.png";
import Image from "components/Image/Image";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setProfile } from "pages/authentication/reducer";
import apis from "services/apis";
import { setStatistic } from "pages/home/reducer";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile?.profile);
  const statistic = useSelector((state) => state.home.statistic);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const catalog = [
    {
      icon: ic_deposit,
      name: t("numberDeposit"),
      link: "deposit",
    },
    {
      icon: ic_withdraw,
      name: t("withdraw"),
      link: "withdraw",
    },
    {
      icon: ic_invoice,
      name: t("invoce"),
      link: "invoice",
    },
    {
      icon: ic_surplus,
      name: t("surplus"),
      link: "surplus",
    },
    {
      icon: ic_share,
      name: t("share"),
      link: "share",
    },
    {
      icon: ic_bank,
      name: t("linkBank"),
      link: "bank",
    },
    {
      icon: ic_security,
      name: t("security"),
      link: "security",
    },
    {
      icon: ic_setting,
      name: t("setting"),
      link: "setting",
    },
  ];

  const _renderVIP = (level) => {
    switch (level) {
      case 1:
        return bg_lv1;
      case 2:
        return bg_lv2;
      case 3:
        return bg_lv3;
      case 4:
        return bg_lv4;
      case 5:
        return bg_lv5;
      case 6:
        return bg_lv6;
      case 7:
        return bg_lv7;
      case 8:
        return bg_lv8;
      case 9:
        return bg_lv9;
      default:
        return bg_lv0;
    }
  };

  const onNavigate = (link) => {
    navigate(link);
  };

  useEffect(() => {
    getProfile();
    getStatistic();
  }, []);

  const getProfile = async () => {
    try {
      const response = await apis.getProfile();
      if (response?.results) {
        dispatch(setProfile(response?.results));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getStatistic = async () => {
    try {
      const response = await apis.getStatistics();
      if (response?.results) {
        dispatch(setStatistic(response?.results));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const _renderMoney = () => {
    return (
      <div>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box style={{ paddingLeft: 16, flex: 1 }}>
            <Text
              title={t("currentBalance")}
              style={{ fontSize: 12, color: "#717171" }}
            />
            <Text
              title={"$" + statistic?.realBalance.toLocaleString() + " USD"}
              style={{
                fontSize: 18,
                color: "#0A0A0A",
                fontWeight: "bold",
                marginTop: 2,
              }}
            />
          </Box>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Box style={{ paddingRight: 16, flex: 1 }}>
            <Text
              title={t("balanceFronzen")}
              style={{ fontSize: 12, color: "#717171" }}
            />
            <Text
              title={"$" + statistic?.frozenBalance.toLocaleString() + " USD"}
              style={{
                fontSize: 18,
                color: "#AF2018",
                fontWeight: "bold",
                marginTop: 2,
              }}
            />
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
      </div>
    );
  };

  return (
    <Box style={{ paddingBottom: 40 }}>
      <Box
        style={{
          backgroundColor: "#000000",
          color: "#FFFFFF",
          padding: "20px",
          position: "relative",
          overflow: "hidden",
          marginBottom: "10px",
          height: 164,
        }}
      >
        <Box
          component="img"
          src={_renderVIP(profile?.package?.level)} // Thay bằng URL của hình nền của bạn
          alt="Background"
          style={{
            width: "100%",
            height: 164,
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 1,
            zIndex: 1,
          }}
        />

        <Box
          style={{
            zIndex: 1000,
            position: "absolute",
            right: "10%",
            top: "47%",
          }}
        >
          <Text title={profile?.username} />
          <Box style={{ display: "flex" }}>
            <Text title={"ID:"} />
            <Text title={profile?._id.slice(0, 6).toUpperCase()} />
          </Box>
        </Box>
      </Box>
      {_renderMoney()}
      <List>
        {catalog.map((item, index) => (
          <ListItem
            onClick={() => onNavigate(item.link)}
            key={index}
            style={{ paddingTop: 10, paddingBottom: 10, alignItems: "center" }}
          >
            <Image src={item.icon} style={{ height: 20, width: 20 }} />
            <Text
              title={item.name}
              style={{ marginLeft: 10, fontSize: 18, color: "#333333" }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ProfilePage;
