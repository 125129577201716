import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const MessageModal = ({ open, image, onClose }) => {
  const renderImage = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          borderRadius: 2,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "grey.500",
            backgroundColor: "#FFFFFF",
          }}
        >
          <CloseIcon />
        </IconButton>
        <img
          src={image}
          style={{
            width: "100%",
            borderRadius: 8,
            height: 550,
            maxHeight: "80vh",
          }}
        />
      </Box>
    );
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {renderImage()}
    </Modal>
  );
};
export default MessageModal;
