import Text from "components/Text/Typography";
import React, { useEffect, useState } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Button,
  Divider,
  Checkbox,
  Chip,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { listBank } from "./data";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducers/snackbarReducer";
import apis from "services/apis";
import { startLoading, stopLoading } from "reducers/loadingReducer";
const BankPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [nameBank, setNameBank] = useState("");
  const [numberBank, setNumberBank] = useState("");

  useEffect(() => {
    getInfomationBank();
  }, []);

  const formatString = (input) => {
    if (input.length <= 4) return input;
    const start = input.slice(0, 3);
    const end = input.slice(-3);
    const middle = "*".repeat(input.length - 6);
    return start + middle + end;
  };

  const getInfomationBank = async () => {
    try {
      const response = await apis.getBank();
      if (response?.results) {
        setFullName(response.results.fullName);
        setNameBank(response.results.nameBank);
        setNumberBank(formatString(response.results.numberBank));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = () => {
    if (!fullName) {
      dispatch(showSnackbar(t("enterFullName")));
      return;
    }

    if (!nameBank) {
      dispatch(showSnackbar(t("choseBank")));
      return;
    }

    if (!numberBank) {
      dispatch(showSnackbar(t("enterNumberBank")));
      return;
    }

    onSubmitBank();
  };

  const onSubmitBank = async () => {
    try {
      dispatch(startLoading());
      const params = {
        fullName: fullName,
        nameBank: nameBank,
        numberBank: numberBank,
        branchBank: nameBank,
        method: "bank",
      };

      await apis.updateBank(params);
      dispatch(stopLoading());
      dispatch(showSnackbar(t("updateSuccess")));
      getInfomationBank();
    } catch (error) {
      console.log("error", error);
      dispatch(stopLoading());
    }
  };

  return (
    <Box>
      <Header
        title={t("myBank").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Text
          title={t("fullName")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          fullWidth
          placeholder={t("fullName")}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
        />
        <Text
          title={t("nameBank")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          value={nameBank}
          onChange={(e) => setNameBank(e.target.value)}
          fullWidth
          placeholder={t("nameBank")}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
        />
        <Text
          title={t("numberBank")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          value={numberBank}
          onChange={(e) => setNumberBank(e.target.value)}
          fullWidth
          placeholder={"123456"}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
        />
        <Button
          onClick={onSubmit}
          fullWidth
          variant="contained"
          style={{
            height: 45,
            borderRadius: 8,
            marginTop: 50,
            backgroundColor: "#333333",
            color: "#FFFFFF",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {t("identify").toUpperCase()}
        </Button>
      </Box>
    </Box>
  );
};
export default BankPage;
