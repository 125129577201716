import React, { useState } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Text from "components/Text/Typography";

const GuidePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <Header
        title={t("guide").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box
        style={{
          marginTop: 16,
          paddingBottom: 60,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Text title={t("guide1")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide2")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide3")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide4")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide5")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide6")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide7")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide8")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide9")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide10")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide11")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide12")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide13")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide14")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide15")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("guide16")} style={{ fontSize: 14, color: "#333333" }} />
      </Box>
    </Box>
  );
};
export default GuidePage;
