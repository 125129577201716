import {
  Box,
  OutlinedInput,
  InputAdornment,
  Button,
  Divider,
  Checkbox,
  Chip,
} from "@mui/material";
import Header from "components/Header";
import Text from "components/Text/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ic_deposit_input from "assets/images/ic_deposit_input.png";
import { useNavigate } from "react-router-dom";
import ic_password from "assets/images/ic_password.png";
import BalanceAccount from "components/BalanceAccount";
import ic_tab_deposit from "assets/images/ic_tab_deposit.png";
import ic_tab_withdraw from "assets/images/ic_tab_withdraw.png";
import Icon from "components/Image/Icon";
import { useDispatch } from "react-redux";
import apis from "services/apis";

const InvoicePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("deposit");

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const formatLabel = (status) => {
    switch (status) {
      case "approved":
        return {
          label: selectedTab === "deposit" ? t("deposited") : t("withdrawed"),
          color: "success",
          backgroundColor: "#D5EEC3",
        };
      case "pending":
        return {
          label:
            selectedTab === "deposit"
              ? t("pendingDeposit")
              : t("pendingWithdraw"),
          color: "default",
          backgroundColor: "#F4F4F4",
        };
      case "rejected":
        return {
          label: t("failed"),
          color: "error",
          backgroundColor: "#FFF0F0",
        };
      default:
        return "";
    }
  };

  useEffect(() => {
    getDeposit();
    getWidthdraw();
  }, []);

  const getDeposit = async () => {
    try {
      const response = await apis.historyDeposit();
      if (response?.results?.trans.length > 0) {
        setData(response?.results.trans);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getWidthdraw = async () => {
    try {
      const response = await apis.historyWithdraw();
      if (response?.results?.datas.length > 0) {
        setData2(response?.results.datas);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Box>
      <Header
        title={t("invoce").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <BalanceAccount />
      <Box
        style={{
          display: "flex",
          paddingLeft: 16,
          paddingRight: 16,
          marginTop: 30,
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            borderBottom:
              selectedTab === "deposit" ? "2px solid #DDA15E" : "none",
            paddingBottom: 8,
            flex: 1,
            justifyContent: "center",
          }}
          onClick={() => handleTabChange("deposit")}
        >
          <Icon src={ic_tab_deposit} />
          <Text
            title={t("deposit")}
            style={{
              fontSize: 14,
              color: "#0A0A0A",
              fontWeight: "bold",
              marginLeft: 6,
            }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            borderBottom:
              selectedTab === "withdraw" ? "2px solid #DDA15E" : "none",
            paddingBottom: 8,
            flex: 1,
            justifyContent: "center",
          }}
          onClick={() => handleTabChange("withdraw")}
        >
          <Icon src={ic_tab_withdraw} />
          <Text
            title={t("withdraw")}
            style={{
              fontSize: 14,
              color: "#0A0A0A",
              fontWeight: "bold",
              marginLeft: 6,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ mx: 2, mt: 2, paddingBottom: 10 }}>
        {(selectedTab === "deposit" ? data : data2).map((transaction) => (
          <Box
            key={transaction.id}
            sx={{
              borderWidth: 1,
              borderColor: "#CECECE",
              borderRadius: 1,
              bgcolor: "#FFFFFF",
              borderStyle: "solid",
              mb: 2,
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                title={
                  selectedTab == "deposit"
                    ? t("statusDeposit")
                    : t("statusWithdraw")
                }
                style={{ fontSize: 12, color: "#717171" }}
              />
              <Chip
                label={formatLabel(transaction.status).label}
                color={formatLabel(transaction.status).color}
                variant="outlined"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 1,
                  backgroundColor: formatLabel(transaction.status)
                    .backgroundColor,
                }}
              />
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Text
                title={
                  new Date(transaction.createdAt).getDate() +
                  "/" +
                 ( new Date(transaction.createdAt).getMonth() + 1)  +
                  "/" +
                  new Date(transaction.createdAt).getFullYear() +
                  " " +
                  new Date(transaction.createdAt).getHours() +
                  ":" +
                  new Date(transaction.createdAt).getMinutes() +
                  ":" +
                  new Date(transaction.createdAt).getSeconds()
                }
                style={{ fontSize: 14, color: "#717171" }}
              />
              <Text
                title={"$ " + transaction.amount.toLocaleString()}
                style={{ fontSize: 14, color: "#0A0A0A", fontWeight: "bold" }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default InvoicePage;
