import { Box } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";

const ShareQRPage = () => {
  const profile = useSelector((state) => state.profile?.profile);
  return (
    <Box>
      <QRCode
        size={220}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={profile?.code}
        viewBox={`0 0 256 256`}
      />
    </Box>
  );
};

export default ShareQRPage;
