import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import LoginPage from "./pages/authentication/Login";
import LanguagePage from "./pages/language/Language";
import RegisterPage from "./pages/authentication/Register";
import MainHomePage from "./pages/home/MainHome";

import HomePage from "./pages/home/Home";
import MatchingPage from "./pages/matching/Matching";
import SupportPage from "./pages/support/Support";
import HistoryPage from "./pages/history/History";

import ProfileLayout from "./pages/profile/ProfileLayout"; // Import ProfileLayout
import ProfilePage from "./pages/profile/Profile";
import DepositPage from "pages/profile/Deposit";
import WithdrawPage from "pages/profile/Withdraw";
import InvoicePage from "pages/profile/Invoice";
import SurplusPage from "pages/profile/Surplus";
import SharePage from "pages/profile/Share";
import ShareQRPage from "pages/profile/ShareQR";
import BankPage from "pages/profile/Bank";

import SecurityLayout from "pages/profile/SecurityLayout";
import SecurityPage from "pages/profile/Security";
import InfoDeliveryPage from "pages/profile/InfoDelivery";
import ManagePasswordPage from "pages/profile/ManagePassword";
import ManagePasswordOrderPage from "pages/profile/ManagePasswordOrder";
import SettingLayout from "pages/profile/SecurityLayout";
import SettingPage from "pages/profile/Setting";
import AgreePage from "pages/profile/Agree";
import RulePage from "pages/profile/Rules";
import AboutPage from "pages/profile/About";
import { useDispatch } from "react-redux";
import { setProfile } from "pages/authentication/reducer";
import { network } from "services";
import apis from "services/apis";

import LiveChatPage from "pages/support/LiveChat";
import { useTranslation } from "react-i18next";

import CompanyPage from "pages/profile/Company";
import GuidePage from "pages/profile/Guide";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      network.setToken(accessToken);
      getProfile();
    }
    getLocalLanguage();
  }, []);

  const getProfile = async () => {
    try {
      const response = await apis.getProfile();
      if (response?.results) {
        dispatch(setProfile(response?.results));
        navigate("/main/home");
      }
    } catch (error) {
      console.log("CHECK ERROR", error);
      navigate("/");
      localStorage.removeItem("token");
    }
  };

  const getLocalLanguage = () => {
    const language = localStorage.getItem("language");
    if (language) {
      i18n.changeLanguage(language);
    }
  };

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/language" element={<LanguagePage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/main" element={<MainHomePage />}>
        <Route path="home" element={<HomePage />} />
        <Route path="history" element={<HistoryPage />} />
        <Route path="matching" element={<MatchingPage />} />
        <Route path="support" element={<SupportPage />} />
        <Route path="profile" element={<ProfileLayout />}>
          <Route path="" element={<ProfilePage />} />
          <Route path="deposit" element={<DepositPage />} />
          <Route path="withdraw" element={<WithdrawPage />} />
          <Route path="invoice" element={<InvoicePage />} />
          <Route path="surplus" element={<SurplusPage />} />
          <Route path="share" element={<SharePage />} />
          <Route path="bank" element={<BankPage />} />
          <Route path="security" element={<SecurityLayout />}>
            <Route path="" element={<SecurityPage />} />
            <Route path="info-delivery" element={<InfoDeliveryPage />} />
            <Route path="manage-password" element={<ManagePasswordPage />} />
            <Route
              path="manage-password-order"
              element={<ManagePasswordOrderPage />}
            />
          </Route>
          <Route path="setting" element={<SettingLayout />}>
            <Route path="" element={<SettingPage />} />
            <Route path="agree" element={<AgreePage />} />
            <Route path="rule" element={<RulePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="company" element={<CompanyPage />} />
            <Route path="guide" element={<GuidePage />} />
          </Route>
        </Route>
      </Route>
      <Route path="share-qr" element={<ShareQRPage />} />
      <Route path="live-chat" element={<LiveChatPage />} />
    </Routes>
  );
};

export default App;
