import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import Header from "components/Header";
import IconButton from "@mui/material/IconButton";
import Text from "components/Text/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ic_showpassword from "../../assets/images/ic_showpassword.png";
import ic_hidepassword from "../../assets/images/ic_hidepassword.png";
import { color } from "framer-motion";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducers/snackbarReducer";
import apis from "services/apis";
import { startLoading, stopLoading } from "reducers/loadingReducer";
const ManagePasswordOrderPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const onSubmit = () => {
    if (!oldPassword) {
      dispatch(showSnackbar(t("enterOldPassword")));
      return;
    }

    if (!newPassword) {
      dispatch(showSnackbar(t("enterNewPassword")));
      return;
    }

    if (!confirmNewPassword) {
      dispatch(showSnackbar(t("reNewPassword")));
      return;
    }

    if (newPassword !== confirmNewPassword) {
      dispatch(showSnackbar(t("passwordNotMatch")));
      return;
    }
    onCallChangePassword();
  };

  const onCallChangePassword = async () => {
    try {
      dispatch(startLoading());
      const params = {
        password: oldPassword,
        passwordLevel2: newPassword,
      };
      const response = await apis.changePasswordOrder(params);
      dispatch(stopLoading());
      dispatch(showSnackbar(t("changePasswordSuccess")));
    } catch (error) {
      console.log("error", error);
      dispatch(stopLoading());
      dispatch(showSnackbar(t("changePasswordFailed")));
    }
  };

  return (
    <Box>
      <Header
        title={t("managePasswordOrder").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Text
          title={t("oldPassword")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          onChange={(e) => setOldPassword(e.target.value)}
          fullWidth
          placeholder={t("enterOldPassword")}
          type={showPassword ? "text" : "password"}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? (
                <img
                  src={ic_hidepassword}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              ) : (
                <img
                  src={ic_showpassword}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              )}
            </IconButton>
          }
        />
        <Text
          title={t("newPassword")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          placeholder={t("enterNewPassword")}
          type={showNewPassword ? "text" : "password"}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowNewPassword(!showNewPassword)}
              edge="end"
            >
              {showNewPassword ? (
                <img
                  src={ic_hidepassword}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              ) : (
                <img
                  src={ic_showpassword}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              )}
            </IconButton>
          }
        />

        <Text
          title={t("reNewPassword")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          fullWidth
          placeholder={t("reNewPassword")}
          type={showConfirmNewPassword ? "text" : "password"}
          sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
              edge="end"
            >
              {showConfirmNewPassword ? (
                <img
                  src={ic_hidepassword}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              ) : (
                <img
                  src={ic_showpassword}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />
              )}
            </IconButton>
          }
        />

        <Text
          title={t("noteManagePassword")}
          style={{ fontSize: 12, color: "#333333", marginTop: 24 }}
        />

        <Button
          onClick={onSubmit}
          fullWidth
          variant="contained"
          style={{
            height: 45,
            borderRadius: 8,
            marginTop: 50,
            backgroundColor: "#333333",
            color: "#FFFFFF",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {t("identify").toUpperCase()}
        </Button>
      </Box>
    </Box>
  );
};
export default ManagePasswordOrderPage;
