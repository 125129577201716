import React, { useState } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import Text from "components/Text/Typography";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ic_delivery from "assets/images/ic_delivery.png";
import ic_manage_password from "assets/images/ic_manage_password.png";
import ic_manage_password_order from "assets/images/ic_manage_password_order.png";
import Image from "components/Image/Image";
import ic_right from "assets/images/ic_right.png";
const SecurityPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onNavigate = (link) => {
    navigate(link);
  };
  const catalog = [
    { icon: ic_delivery, name: t("infoDelivery"), link: "info-delivery" },
    {
      icon: ic_manage_password,
      name: t("managePassword"),
      link: "manage-password",
    },
    {
      icon: ic_manage_password_order,
      name: t("managePasswordOrder"),
      link: "manage-password-order",
    },
  ];
  return (
    <Box>
      <Header
        title={t("security").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box>
        <List>
          {catalog.map((item, index) => (
            <ListItem
              onClick={() => onNavigate(item.link)}
              key={index}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                alignItems: "center",
              }}
            >
              <Image src={item.icon} style={{ height: 20, width: 20 }} />
              <Text
                title={item.name}
                style={{
                  marginLeft: 10,
                  fontSize: 18,
                  color: "#333333",
                  flex: 1,
                }}
              />
              <Image src={ic_right} style={{ height: 20, width: 20 }} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};
export default SecurityPage;
