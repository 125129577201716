import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  statistic: null,
};

const homeSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setStatistic: (state, action) => {
      state.statistic = action.payload;
    },
  },
});

export const { setStatistic } = homeSlice.actions;
export default homeSlice.reducer;
