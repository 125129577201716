import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Divider,
} from "@mui/material";
import Image from "./Image/Image";
import { useTranslation } from "react-i18next";

const OrderCard = ({ order, onSubmit }) => {
  const { t } = useTranslation();
  const formatButton = (status) => {
    switch (status) {
      case "pending":
        return {
          name: t("pending"),
          backgroundColor: "#FBE7C7",
          color: "#CC6900",
        };
      case "done":
        return {
          name: t("done"),
          backgroundColor: "#D5EEC3",
          color: "#006A00",
        };

      default:
        return "";
    }
  };
  return (
    <Card sx={{ mb: 2, borderRadius: 2, borderColor: "divider", boxShadow: 3 }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "16px !important",
          backgroundColor: "#F3F3F3",
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("time") + ": "}
          {new Date(order.updatedAt).getDate() +
            "/" +
            (new Date(order.updatedAt).getMonth() + 1) +
            "/" +
            new Date(order.updatedAt).getFullYear() +
            " " +
            new Date(order.updatedAt).getHours() +
            ":" +
            new Date(order.updatedAt).getMinutes() +
            ":" +
            new Date(order.updatedAt).getSeconds()}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {t("codeOrder") + ":"} {order?.orderCode}
          </Typography>
          <Box
            sx={{
              backgroundColor: formatButton(order.status).backgroundColor,
              color: formatButton(order.status).color,
              borderRadius: 2,
              padding: "4px 8px",
              display: "inline-block",
            }}
          >
            {formatButton(order.status).name}
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: 2,
            marginTop: 2,
            padding: 2,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              component="img"
              image={order.product.image}
              sx={{ width: 200, height: 200, borderRadius: 1, mr: 2 }}
            />
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {order.product.name}
              </Typography>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 1,
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  ${order?.minToup ? order.minToup : order.product.price}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  x1.00
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider style={{ marginTop: 10 }} />
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              marginTop: 1,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {t("totalOrder")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              ${order?.minToup ? order.minToup : order.product.price}
            </Typography>
          </Box>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              marginTop: 1,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {t("discount")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              ${order.commission}
            </Typography>
          </Box>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              marginTop: 1,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {t("refund")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              $
              {(order?.minToup ? order.minToup : order.product.price) +
                order.commission}
            </Typography>
          </Box>
          <Box
            style={{
              flex: 1,
              justifyContent: "flex-end",
              alignItem: "center",
              display: "flex",
              marginTop: 1,
            }}
          >
            {order.status === "pending" && (
              <Button
                onClick={onSubmit}
                variant="contained"
                color="error"
                size="small"
                sx={{ mt: 1, borderRadius: 2, textTransform: "none" }}
              >
                {t("sendOrder")}
              </Button>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrderCard;
