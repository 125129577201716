import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Modal,
  Typography,
  Divider,
  IconButton,
  Paper,
} from "@mui/material";
import Header from "components/Header";
import React, { useEffect, useState } from "react";
import bg_matching from "assets/images/bg_matching.png";
import Image from "components/Image/Image";
import Text from "components/Text/Typography";
import ic_maching_money from "assets/images/ic_matching_money.png";
import ic_maching_money2 from "assets/images/ic_matching_money2.png";
import ic_matching_task from "assets/images/ic_matching_task.png";
import ic_matching_profit from "assets/images/ic_matching_profit.png";
import bg_product from "assets/images/bg_production.png";
import ic_loading from "assets/images/ic_loading.gif";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import apis from "services/apis";
import { useDispatch, useSelector } from "react-redux";
import { setStatistic } from "pages/home/reducer";
import { showSnackbar } from "reducers/snackbarReducer";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate } from "react-router-dom";
const MatchingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const profile = useSelector((state) => state.profile?.profile);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isMatching, setIsMatching] = useState(false);
  const statistic = useSelector((state) => state.home.statistic);

  const [matching, setMatching] = useState(null);

  const [messageMatching, setMessageMatching] = useState("");

  const data = [
    {
      value: `${statistic?.interestBalance.toLocaleString()} USD`,
      label: t("commission"),
      imageUrl: ic_matching_profit,
    },
    {
      value: `${statistic?.realBalance.toLocaleString()} USD`,
      label: t("balanceAccount"),
      imageUrl: ic_maching_money,
    },
    {
      value: `${statistic?.orderOfDay}`,
      label: t("taskToday"),
      imageUrl: ic_matching_task,
    },
    {
      value: `${statistic?.earnOfDay.toLocaleString()} USD`,
      label: t("totalToday"),
      imageUrl: ic_maching_money2,
    },
  ];

  const [products, setProducts] = useState([]);

  const handleOpen = () => {
    setLoading(true);
    getGenereOrder();
  };

  const getProduct = async () => {
    try {
      const response = await apis.getListProduct();
      if (response?.results) {
        setProducts(response?.results);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getGenereOrder = async () => {
    try {
      const response = await apis.generateOrder();
      if (response?.results) {
        await delay(2000);
        setLoading(false);
        setOpen(true);
        setIsMatching(true);
        setMatching(response?.results);
      }
    } catch (error) {
      setOpen(false);
      setLoading(false);
      if (!profile?.package) {
        dispatch(showSnackbar(t("errorVIP")));
        return;
      }
      if (error?.error === "HAS_ORDER_PAYMENT") {
        dispatch(showSnackbar(t("uncompletedTask")));
        return;
      }

      if (error?.error === "error.ERROR_MAX_ORDER") {
        dispatch(showSnackbar(t("maxOrder")));
        return;
      }

      dispatch(showSnackbar(t("errorSystem")));
    }
  };

  useEffect(() => {
    getStatistic();
    getProduct();
  }, []);

  const getStatistic = async () => {
    try {
      const response = await apis.getStatistics();
      if (response?.results) {
        dispatch(setStatistic(response?.results));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = async () => {
    try {
      const response = await apis.updateOrder(matching?._id);
      if (response?.results) {
        setOpen2(true);
        setMessageMatching(t("messMatching1"));
        await delay(1500);
        setMessageMatching(t("messMatching2"));
        await delay(1500);
        setMessageMatching(t("messMatching3"));
        await delay(1500);
        setMessageMatching(t("messMatching4"));
        await delay(1500);
        setMessageMatching(t("messMatching5"));
        await delay(1500);
        setMessageMatching(t("messMatching6"));
        await delay(1500);
        dispatch(showSnackbar(t("orderDone")));
        setLoading(false);
        setIsMatching(false);
        setOpen2(false);
        setOpen(false);
        getStatistic();
      }
    } catch (error) {
      console.log("error", error);
      if (error?.error === "error.ERROR_TOPUP_REQUIRED") {
        dispatch(showSnackbar(t("lackOfMoney")));
        navigate("/main/profile/deposit");
      }
    }
  };

  const DashboardCard = ({ value, label, imageUrl }) => (
    <Grid item xs={6}>
      <Box
        sx={{
          padding: 2,
          borderRadius: 4,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
        }}
      >
        <img
          src={imageUrl}
          alt={label}
          style={{ width: 50, height: 50, marginBottom: 8 }}
        />
        <Text title={value} style={{ fontSize: 14, color: "#AF2018" }} />
        <Text title={label} style={{ fontSize: 12, color: "#333333" }} />
      </Box>
    </Grid>
  );

  const _renderCenter = () => {
    return (
      <Box
        style={{
          marginTop: 10,
          backgroundColor: "#F4F4F4",
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 16,
          paddingRight: 16,
          borderRadius: 8,
        }}
      >
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {data.map((item, index) => (
            <DashboardCard
              key={index}
              value={item.value}
              label={item.label}
              imageUrl={item.imageUrl}
            />
          ))}
        </Grid>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text
            title={t("processingDone")}
            style={{ fontSize: 12, color: "#333333" }}
          />
          <Text
            title={
              statistic?.totalOrder +
              "/" +
              (profile?.package?.totalOrder || "0")
            }
            style={{ fontSize: 12, color: "#333333", fontWeight: "bold" }}
          />
        </Box>
        <LinearProgress
          variant="determinate"
          value={(statistic?.totalOrder / profile?.package?.totalOrder) * 100}
          sx={{
            height: 8,
            borderRadius: 5,
            backgroundColor: "#E0E0E0",
            "& .MuiLinearProgress-bar": { backgroundColor: "#D1A17F" },
            marginTop: 1,
          }}
        />
        <Button
          onClick={handleOpen}
          style={{
            backgroundColor: "#333333",
            width: "100%",
            marginTop: 14,
            color: "#FFFFFF",
            height: 40,
            borderRadius: 10,
          }}
        >
          {t("start2")}
        </Button>
      </Box>
    );
  };

  const _renderProduct = () => {
    return (
      <Box style={{ marginTop: 10, paddingBottom: 50 }}>
        <Text
          title={t("generalRules").toUpperCase()}
          style={{ fontSize: 20, fontWeight: "600", color: "#0A0A0A" }}
        />
        <Text style={{ fontSize: 12 }} title={t("rules1")} />
        <Text style={{ fontSize: 12 }} title={t("rules2")} />
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Grid container spacing={2}>
            {products.map((product) => (
              <Grid item xs={6} key={product.id}>
                <Image
                  src={product.image}
                  style={{ height: 200, width: "100%" }}
                />
                <Text
                  title={product.name}
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#0A0A0A",
                    marginTop: 5,
                  }}
                />
                <Text
                  title={"$" + product.price}
                  style={{ fontSize: 14, color: "#AF2018" }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  };

  const _renderLoading = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          p: 4,
          borderRadius: 1,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            borderRadius: 8,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Text
            title={t("gettingBill") + "..."}
            style={{ fontSize: 20, color: "#00FFFF" }}
          />
          <Image
            src={ic_loading}
            style={{ width: 120, height: 120, marginTop: 5 }}
          />

          <Box>
            <Text
              title={t("note1")}
              style={{
                fontSize: 12,
                color: "#99FFFF	",
                textAlign: "left",
                marginTop: 10,
              }}
            />
            <Text
              title={t("note2")}
              style={{
                fontSize: 12,
                color: "#FFFF33	",
                textAlign: "left",
                marginTop: 10,
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const _renderLoading2 = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          p: 4,
          borderRadius: 1,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PulseLoader
          color={"#FFFFFF"}
          loading={true}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <Box>
          <Text
            title={messageMatching}
            style={{
              fontSize: 18,
              color: "#FFFFFF",
              textAlign: "left",
              marginTop: 10,
            }}
          />
        </Box>
      </Box>
    );
  };

  const _renderMatching = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          bgcolor: "background.paper",
          boxShadow: 24,
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="subtitle1" component="p"></Typography>
        <Box style={{ display: "flex" }}>
          <Text
            title={t("codeOrder") + ": "}
            style={{ fontSize: 14, color: "#717171" }}
          />
          <Text
            title={matching?.orderCode}
            style={{ fontSize: 14, color: "#0A0A0A", fontWeight: "500" }}
          />
        </Box>

        <Box style={{ display: "flex" }}>
          <Text
            title={t("balanceAccount") + ": "}
            style={{ fontSize: 14, color: "#717171" }}
          />
          <Text
            title={"$" + statistic?.realBalance.toLocaleString()}
            style={{ fontSize: 14, color: "#0A0A0A", fontWeight: "500" }}
          />
        </Box>

        <Box sx={{ mt: 2, display: "flex" }}>
          <Box
            component="img"
            src={matching?.product?.image}
            alt="Product"
            sx={{ width: 300, height: 150, mr: 2 }}
          />
          <Box>
            <Text
              title={matching?.product?.name}
              style={{ fontSize: 16, color: "#0A0A0A", fontWeight: "500" }}
            />
            <Text
              title={"$" + matching?.product?.price}
              style={{ fontSize: 16, color: "#0A0A0A", fontWeight: "500" }}
            />
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Text
            title={t("totalOrder")}
            style={{ fontSize: 14, color: "#333333" }}
          />
          <Text
            title={"$" + matching?.total}
            style={{ fontSize: 14, color: "#333333" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 4,
          }}
        >
          <Text
            title={t("discount")}
            style={{ fontSize: 14, color: "#333333" }}
          />
          <Text
            title={"$" + matching?.commission}
            style={{ fontSize: 14, color: "#333333" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 4,
          }}
        >
          <Text
            title={t("refund")}
            style={{ fontSize: 16, color: "#333333" }}
          />
          <Text
            title={
              "$" + (matching?.total + matching?.commission).toLocaleString()
            }
            style={{ fontSize: 16, color: "#0A0A0A", fontWeight: "bold" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 4,
          }}
        >
          <Text
            title={t("numberTask")}
            style={{ fontSize: 14, color: "#333333" }}
          />
          <Text
            title={
              statistic?.totalOrder + 1 + "/" + profile?.package?.totalOrder
            }
            style={{ fontSize: 14, color: "#333333" }}
          />
        </Box>
        <Button
          onClick={onSubmit}
          style={{
            backgroundColor: "#DC362E",
            color: "#FFFFFF",
            height: 40,
            borderRadius: 10,
            marginTop: 25,
            width: "100%",
          }}
        >
          {t("sendOrder2")}
        </Button>
      </Box>
    );
  };
  return (
    <Box>
      <Header title={"MICHAEL KORS"} hideBack />
      <Box style={{ paddingLeft: 16, paddingRight: 16, marginTop: 20 }}>
        <Image src={bg_matching} style={{ height: 180, width: "100%" }} />
        {_renderCenter()}
        {_renderProduct()}
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>{isMatching && _renderMatching()}</>
      </Modal>
      <Modal
        open={open2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {_renderLoading2()}
      </Modal>
      {isLoading && _renderLoading()}
    </Box>
  );
};

export default MatchingPage;
